import React, { useCallback, useContext } from 'react';
import logo from '../../../assets/images/skydev-logo.png';
import { Link, useNavigate, useMatch, useResolvedPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthContext } from 'context/AuthContext';
import useGetLinkLiveStreamAndView360 from 'commons/hooks/useGetLinkLiveStreamAndView360';
const CustomLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link style={{ color: match ? '#c99b52' : '#ffffff' }} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
};
const Header = ({ ...props }) => {
  const { data } = useGetLinkLiveStreamAndView360();
  console.log(data);
  let navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const goLogin = () => {
    navigate('/login');
  };
  const goLogout = useCallback(() => {
    logout();
    navigate('/');
  }, [navigate, logout]);
  console.log(user);
  return (
    <header id='header' className='animate__animated animate__fadeIn'>
      <div className='container'>
        <div className='header'>
          <div className='logo'>
            <CustomLink className='navbar-brand' to='/'>
              <img style={{ height: 100 }} src={logo} alt='logo' />
            </CustomLink>
          </div>
          <div className='navigation'>
            <ul className='navbar-nav'>
              <li className='nav-item nav-active'>
                <CustomLink className='nav-link' to='/'>
                  Trang chủ
                </CustomLink>
              </li>
              <li className='nav-item'>
                <CustomLink
                  className='nav-link'
                  target='_blank'
                  to={{ pathname: '//gotecland.vn/tin-tuc/' }}
                >
                  Tin tức
                </CustomLink>
              </li>
              {user?.userName && (
                <>
                  {user?.roleLevel === 4 && (
                    <li className='nav-item'>
                      <CustomLink className='nav-link' to='/cart-public'>
                        Giỏ hàng tổng
                      </CustomLink>
                    </li>
                  )}
                  {user?.roleLevel === 4 ? (
                    <li className='nav-item'>
                      <CustomLink className='nav-link' to='/cart'>
                        Giỏ hàng riêng
                      </CustomLink>
                    </li>
                  ) : (
                    <li className='nav-item'>
                      <CustomLink
                        className='nav-link'
                        to={`/cart-private/${user?.agentId}`}
                      >
                        Giỏ hàng riêng
                      </CustomLink>
                    </li>
                  )}
                  <li className='nav-item'>
                    <CustomLink className='nav-link' to='/live-stream'>
                      Vào sự kiện
                    </CustomLink>
                  </li>
                  <li className='nav-item'>
                    <CustomLink className='nav-link' to='/view-360'>
                      View 360
                    </CustomLink>
                  </li>
                </>
              )}
            </ul>
            {user?.userName ? (
              <button onClick={goLogout}>Đăng Xuất</button>
            ) : (
              <button onClick={goLogin}>Đăng nhập</button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default connect()(Header);
