import { useState, useEffect, useCallback } from "react";
import cookie from "js-cookie";
import dayjs from "dayjs";
import services from "commons/apis/index";

const useGetLinkLiveStreamAndView360 = () => {
  const [data, setData] = useState([]);
  const getData = useCallback(async () => {
    try {
      const result = await services.getLinkLiveAndView360();
      if (!result) {
        return;
      }
      setData(result);
      cookie.set("live-stream", JSON.stringify({ link: result[0].fileName, present: dayjs() }));
      cookie.set("view-360", JSON.stringify({ link: result[1].fileName, present: dayjs() }));
    } catch (error) {
      cookie.set("live-stream", JSON.stringify({ link: "https://www.youtube.com/embed/OmWLaJd9vGE", present: dayjs() }));
      cookie.set("view-360", JSON.stringify({ link: "https://vt2020.vn/ThaoDienGreen-SIC2/", present: dayjs() }));
      console.log(error);
    }
  }, []);
  useEffect(() => {
    const present = dayjs();
    const daySaved = cookie.get("live-stream") && JSON.parse(cookie.get("live-stream"));
    const token = cookie.get("token") && cookie.get("token");
    let hours = daySaved?.present ? present.diff(daySaved?.present, 'hours') : 24;
    const days = Math.floor(hours / 24);
    if ((days === 1) && token) {
      getData();
    }
  }, [getData]);
  return {
    data
  }
}
export default useGetLinkLiveStreamAndView360;
