import React, { useCallback, useContext } from "react";
import { Form, Input, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import logoRiverside from '../../assets/images/logo-riverside.png'
import logo from '../../assets/images/skydev-logo.png'
import userIcon from '../../assets/images/user-icon.png'
import styles from "./Login.module.less";
import { AuthContext } from "context/AuthContext";

const Login = ({ ...props }) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const { login, loading, user } = useContext(AuthContext);
  const onFinish = useCallback(async (values) => {
    login(values).then(() => {
      navigate("/live-stream")
    })
  }, [login, navigate])

  return (
    <div className={`${styles.login} container-fix bg-img-login`} >
      <div className="body-container">
        <div className="animate__animated animate__fadeIn" id={styles.loginPage}>
          <div><div className="logo">
            <a href="/">
              <img src={logo} style={{height:150}} alt="logo" /></a>
          </div>
            <h2>SỰ KIỆN RA MẮT DỰ ÁN</h2>
            <h3>EXCLUSIVE LAUCH EVENT OF</h3>
            <div className="project">
              <img src={logoRiverside} alt="riverside" />
            </div>
            <div className={styles.title}>
              <img src={userIcon} alt="Real-IQ" />
              <p>Vui lòng nhập thông tin của bạn</p>
            </div>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
              <Form.Item
                name="userNameOrEmailAddress"
                rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
                className="form-group"
              >
                <Input className="form-control" placeholder="Địa chỉ email"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                className="form-group"
              >
                <Input
                  type="password"
                  placeholder="Mật khẩu"
                  className="form-control"
                  autoComplete="off"

                />
              </Form.Item>
              <Form.Item shouldUpdate className="text-center">
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    className="btn btn-primary"
                    loading={loading}
                  >
                    Đăng nhập/ Enter
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login